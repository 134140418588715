













import { AgGridVue } from 'ag-grid-vue'
import { Vue, Component, Prop } from 'vue-property-decorator'
import AgGridFactory from '@/factories/agGridFactory'
import ActionRenderer from '@/components/table/ActionRenderer.vue'
import PortfolioFactory from '@/factories/portfolio'
import _ from 'lodash'
import MoodleStatisticModal from '@/components/statitics/MoodleStatisticModal.vue'
import ExcelAPI from '@/api/requests/excel'
import { userMapper } from '@/store/modules/user'

const Mapper = Vue.extend({
  computed: {
    ...userMapper.mapGetters(['isStudent'])
  }
})

@Component({
  components: {
    MoodleStatisticModal,
    AgGridVue,
    ActionRenderer
  }
})
export default class MoodlePortfolioStudent extends Mapper {
  $refs!: {
    moodleStatisticModal: MoodleStatisticModal
  }
  private title = 'Статистика прохождения курса'
  @Prop({ type: Array, default: [] }) readonly courses!: any[]
  private courseModel = PortfolioFactory.emptyCourses()
  private defaultDefs = [
    {
      headerName: 'Курс',
      minWidth: 100,
      field: 'title',
      colId: 'course',
      cellStyle: {
        'text-decoration': 'underline',
        cursor: 'pointer'
      },
      cellClass: 'courseStyle'
    },
    {
      headerName: 'Балл',
      width: 100,
      suppressSizeToFit: true,
      field: 'grade',
      colId: 'score'
    },
    {
      ...AgGridFactory.getActionColumn({
        cellRendererParams: {
          onExcel: this.downloadXlsxReport,
          onStatistics: this.onStatisticsModal
        }
      }),
      width: 120
    }
  ]

  private updateRightsAction() {
    this.defaultDefs[this.defaultDefs.length - 1].hide = this.isStudent
  }

  private get columnDefs() {
    return this.defaultDefs
  }

  private onStatisticsModal(params: any) {
    this.courseModel = _.cloneDeep(params.node.data)
    this.title = `Статистика прохождения курса "${params.node.data.title}"`
    this.$refs.moodleStatisticModal.openStatisticModal(this.courseModel)
    this.$bvModal.show('statisticMoodle')
  }

  private async downloadXlsxReport(params: any) {
    await ExcelAPI.getCourseResultsByStudent(
      params.node.data.userId,
      params.node.data.moodleId
    )
  }

  private gridOptions = {
    ...AgGridFactory.getDefaultGridOptions(),
    pagination: true,
    paginationPageSize: 30,
    onCellClicked: this.onCellClicked
  }

  private onCellClicked(event: any) {
    if (event.colDef.colId === 'course' && event?.data?.URL) {
      window.open(event.data.URL, '_blank')
    }
  }
  private created() {
    this.updateRightsAction()
  }
}
