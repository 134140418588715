























import { Component, Vue } from 'vue-property-decorator'
import { FormOptions, FormSchema } from 'vue-form-generator'
import UserFactory from '@/factories/user'
import { regMail } from '@/config/default/regex'
import { minLengthPassword } from '@/config/default/user'
import UserAPI from '@/api/requests/user'
import { toastMapper } from '@/store/modules/toast'

const Mapper = Vue.extend({
  methods: {
    ...toastMapper.mapActions(['pushToast'])
  }
})

@Component
export default class ChangeMailModal extends Mapper {
  private userModel = UserFactory.emptyMail()
  private isValidForm = false

  private modalSchemaMail: FormSchema = {
    fields: [
      {
        type: 'bootstrap',
        inputType: 'email',
        placeholder: 'student-leti@etu.ru',
        model: 'newMail',
        label: 'Введите новую почту',
        styleClasses: 'w-100',
        validator(value, field, model) {
          if (!regMail.test(model.newMail)) {
            return ['Проверьте правильность ввода почты']
          }
          return []
        }
      },
      {
        type: 'bootstrap',
        inputType: 'password',
        model: 'currentPassword',
        label: 'Введите текущий пароль',
        styleClasses: 'w-100',
        validator(value, field, model) {
          if (model.currentPassword.length < minLengthPassword) {
            return [
              `Пароль должен быть не меньше ${minLengthPassword} символов`
            ]
          }
          return []
        }
      }
    ]
  }

  private onChangeMailConfirm() {
    const changingMail = {
      password: this.userModel.currentPassword,
      newMail: this.userModel.newMail
    }
    try {
      UserAPI.emailConfirmation(changingMail)
      this.pushToast({
        title: 'Смена почты',
        message: `На почту <strong>${this.userModel.newMail}</strong> отправлено сообщение со ссылкой для смены почты`,
        time: 5
      })
    } catch {
      this.pushToast({
        error: true,
        title: 'Ошибка',
        message: 'Не удалось изменить почту',
        time: 5
      })
    }
  }

  private options: FormOptions = {
    validateAfterLoad: true,
    validateAfterChanged: true,
    validateAsync: true
  }

  private onValidated(isValid: boolean) {
    this.isValidForm = isValid
  }

  private onHidden() {
    this.userModel = UserFactory.emptyMail()
  }
}
