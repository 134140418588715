export enum supportedExtensions {
  'jpeg' = 'jpeg',
  'jpg' = 'jpg',
  'png' = 'png',
  '' = '' // Empty
}

export enum fileFormats {
  'image' = 'image'
}

export const extensionToContentType: { [k: string]: string } = {
  [supportedExtensions.jpeg]: 'image/jpeg',
  [supportedExtensions.jpg]: 'image/jpeg',
  [supportedExtensions.png]: 'image/png'
}

// In Mb
export const maximumSizeOfLoadFileByUser: { [k: string]: number } = {
  [supportedExtensions.jpeg]: 10,
  [supportedExtensions.jpg]: 10,
  [supportedExtensions.png]: 10
}

export const imageFormats: { [k: string]: supportedExtensions } = {
  [supportedExtensions.jpeg]: supportedExtensions.jpeg,
  [supportedExtensions.jpg]: supportedExtensions.jpg,
  [supportedExtensions.png]: supportedExtensions.png
}

export const formatToValidator: { [k: string]: any } = {
  [fileFormats.image]: imageFormats
}
