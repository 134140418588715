












import { AgGridVue } from 'ag-grid-vue'
import { Vue, Component, Prop } from 'vue-property-decorator'
import AgGridFactory from '@/factories/agGridFactory'

@Component({
  components: {
    AgGridVue
  }
})
export default class EventsPortfolioStudent extends Vue {
  @Prop({ type: Array, default: [] }) readonly events!: any[]
  private defaultDefs = [
    {
      headerName: 'Событие',
      minWidth: 150,
      field: 'title',
      colId: 'event',
      cellStyle: {
        'text-decoration': 'underline',
        cursor: 'pointer'
      },
      cellClass: 'eventStyle'
    },
    {
      headerName: 'Дата события',
      width: 200,
      suppressSizeToFit: true,
      field: 'eventDate',
      colId: 'date'
    }
  ]

  private get columnDefs() {
    return this.defaultDefs
  }

  private gridOptions = {
    ...AgGridFactory.getDefaultGridOptions(),
    pagination: true,
    paginationPageSize: 30,
    onCellClicked: this.onCellClicked
  }

  private onCellClicked(event: any) {
    if (event.colDef.colId === 'event' && event?.data?.id) {
      const routeData = this.$router.resolve({
        name: 'EventsViewer',
        params: { id: event.data.id }
      })
      window.open(routeData.href, '_blank')
    }
  }
}
