







import { Vue, Component } from 'vue-property-decorator'
import MoodlePortfolioStudent from '@/components/student/MoodlePortfolioStudent.vue'
import EventsStudent from '@/components/student/EventsPortfolioStudent.vue'
import { userMapper } from '@/store/modules/user'
import { roles } from '@/config/types/roles'
import PortfolioAPI from '@/api/requests/portfolio'
import PortfolioFactory from '@/factories/portfolio'
import { PortfolioAdapter } from '@/api/adapters/portfolio'
import _ from 'lodash'

const Mapper = Vue.extend({
  computed: {
    ...userMapper.mapGetters(['currentUser'])
  }
})
@Component({
  components: {
    MoodlePortfolioStudent,
    EventsStudent
  }
})
export default class Portfolio extends Mapper {
  private courses = PortfolioFactory.emptyCourses()
  private events = PortfolioFactory.emptyEvents()
  private portfolio: any = null

  private async created() {
    if (!this.currentUser.roles.includes(roles.student)) {
      const userId = parseInt(this.$route.params.id)
      if (!_.isNaN(userId)) {
        this.portfolio = (await PortfolioAPI.getStudentPortfolio(userId)).data
      }
    } else {
      this.portfolio = (await PortfolioAPI.getMyPortfolio()).data
    }
    this.courses = PortfolioAdapter.changeCoursesDateFormatToDisplay(
      this.portfolio.courses
    )
    this.events = PortfolioAdapter.changeEventsDateFormatToDisplay(
      this.portfolio.events
    )
  }
}
