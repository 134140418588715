

















































import { Component, Vue } from 'vue-property-decorator'
import { FormOptions, FormSchema } from 'vue-form-generator'
import {
  notifications,
  notificationsTranslation
} from '@/config/types/notificationsSubscribe'
import NotificationsAPI from '@/api/requests/notifications'
import { ID_GROUP_VK } from '@/config/types/notificationTypes'
import { userMapper } from '@/store/modules/user'

const Mapper = Vue.extend({
  computed: {
    ...userMapper.mapGetters([
      'userEmailNotif',
      'userNotif',
      'userEvents',
      'userNews'
    ])
  },
  methods: {
    ...userMapper.mapActions(['fetchCurrentUser'])
  }
})
@Component
export default class NotificationsSettingsModal extends Mapper {
  private isConnectionNotificationsMail = false
  private userNotifications = {
    notifications: [] as any
  }

  private created() {
    this.displayNotifications()
    this.isConnectionNotificationsMail = !!this.userEmailNotif
  }
  private modalSchemaSettingsNotifications: FormSchema = {
    fields: [
      {
        type: 'multiselect',
        model: 'notifications',
        label: 'Выберите из списка уведомления, которые хотите получать',
        options: Object.values(notificationsTranslation),
        styleClasses: 'w-100 mt-4',
        multiple: true
      }
    ]
  }
  private displayNotifications() {
    if (
      this.userNotif &&
      !this.userNotifications.notifications.includes(
        notificationsTranslation[notifications.notifications]
      )
    ) {
      this.userNotifications.notifications = [
        ...this.userNotifications.notifications,
        notificationsTranslation[notifications.notifications]
      ]
    }
    if (
      this.userEvents &&
      !this.userNotifications.notifications.includes(
        notificationsTranslation[notifications.events]
      )
    ) {
      this.userNotifications.notifications = [
        ...this.userNotifications.notifications,
        notificationsTranslation[notifications.events]
      ]
    }
    if (
      this.userNews &&
      !this.userNotifications.notifications.includes(
        notificationsTranslation[notifications.news]
      )
    ) {
      this.userNotifications.notifications = [
        ...this.userNotifications.notifications,
        notificationsTranslation[notifications.news]
      ]
    }
  }
  private onChangeNotificationsInput(newVal: any) {
    this.userNotifications.notifications.getNotif = newVal.includes(
      notificationsTranslation[notifications.notifications]
    )
    this.userNotifications.notifications.getEvents = newVal.includes(
      notificationsTranslation[notifications.events]
    )
    this.userNotifications.notifications.getNews = newVal.includes(
      notificationsTranslation[notifications.news]
    )
  }
  private options: FormOptions = {
    validateAfterLoad: false,
    validateAfterChanged: false
  }
  private async onSaveClick() {
    const notifToServer = {
      getNotif: this.userNotifications.notifications.includes(
        notificationsTranslation[notifications.notifications]
      ),
      getEvents: this.userNotifications.notifications.includes(
        notificationsTranslation[notifications.events]
      ),
      getNews: this.userNotifications.notifications.includes(
        notificationsTranslation[notifications.news]
      )
    }

    await NotificationsAPI.editNotifications(notifToServer)
    await this.fetchCurrentUser(true)
    this.displayNotifications()
  }
  private subscribeNotificationsVk(vkId: number) {
    NotificationsAPI.vkNotifications(vkId)
  }
  private unSubscribeNotificationsVk() {
    NotificationsAPI.vkNotifications(null)
  }
  private onNotificationsMailClick() {
    this.isConnectionNotificationsMail = !this.isConnectionNotificationsMail
    NotificationsAPI.emailNotifications(this.isConnectionNotificationsMail)
  }

  private addVkWidget() {
    this.loadVkWidget().then(() => {
      this.addObservers()
    })
  }
  private loadVkWidget() {
    return new Promise<void>(resolve => {
      const widget = document.createElement('script')
      widget.onload = () => {
        resolve()
      }
      widget.async = true
      widget.type = 'text/javascript'
      widget.src = 'https://vk.com/js/api/openapi.js?169'
      document.head.appendChild(widget)
    })
  }
  private addObservers() {
    window.VK.Widgets.AllowMessagesFromCommunity(
      'vk-widget',
      { height: 30 },
      ID_GROUP_VK
    )

    window.VK.Observer.subscribe(
      'widgets.allowMessagesFromCommunity.allowed',
      this.subscribeNotificationsVk
    )
    window.VK.Observer.subscribe(
      'widgets.allowMessagesFromCommunity.denied',
      this.unSubscribeNotificationsVk
    )
  }
  private onHidden() {
    this.$emit('openVkWidget', false)
  }

  public openModal() {
    this.addVkWidget()
  }
}
