import { ContentAdapter } from '@/api/adapters/content'

export class PortfolioAdapter {
  public static changeEventsDateFormatToDisplay(content: any) {
    for (const element of content) {
      element.eventDate = element.eventDate
        ? ContentAdapter.castDateToLocal(element.eventDate)
        : element.eventDate
    }
    return content
  }

  public static changeCoursesDateFormatToDisplay(content: any) {
    for (const element of content) {
      element.completionDate = element.completionDate
        ? ContentAdapter.castDateToLocal(element.completionDate)
        : element.completionDate
    }
    return content
  }
}
