import {
  extensionToContentType,
  fileFormats,
  maximumSizeOfLoadFileByUser,
  supportedExtensions,
  formatToValidator
} from '@/config/default/extensions'

export function onImageInput(file: any): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      resolve(reader.result as string)
    }
    reader.onloadend = () => {
      reject('FileReader failed')
    }
  })
}

export function convertBToMB(value: number): number {
  return Math.ceil(value / 1024 / 1024)
}

export function isValidLoadedFile(
  file: File,
  format?: fileFormats
): { isOk: boolean; error: string } {
  const size = file?.size ?? 0
  const type = file?.type ?? ''
  const extension = ((file?.name ?? '').split('.').pop() ??
    supportedExtensions['']) as supportedExtensions
  if (format && !Object.values(formatToValidator[format]).includes(extension)) {
    return {
      isOk: false,
      error:
        'Загружаемый файл не соответствует требуемому типу. Загрузите файл другого расширения. '
    }
  }
  if (
    Object.values(formatToValidator).reduce(
      (isValid: boolean, mapper: { [k: string]: supportedExtensions }) => {
        return isValid || Object.values(mapper).includes(extension)
      },
      false
    )
  ) {
    if (extensionToContentType[extension] !== type)
      return {
        isOk: false,
        error: 'У данного файла указано неверное расширение. Проверьте файл.'
      }

    if (maximumSizeOfLoadFileByUser[extension] < convertBToMB(size)) {
      return {
        isOk: false,
        error: 'Загружаемый файл слишком большой. Попробуйте сжать файл.'
      }
    }
    return { isOk: true, error: '' }
  }

  return { isOk: false, error: 'Неподдерживаемый формат файла.' }
}
