import { http } from '@/api/requests/httpAxios'
import { AxiosPromise } from 'axios'

export default class PortfolioAPI {
  public static getStudentPortfolio(id: number): AxiosPromise {
    return http.get(`users/${id}/portfolio`)
  }
  public static getMyPortfolio(): AxiosPromise {
    return http.get('users/0/portfolio')
  }
}
