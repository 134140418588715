























import { Vue, Component } from 'vue-property-decorator'
import { FormOptions, FormSchema } from 'vue-form-generator'
import UserFactory from '@/factories/user'
import { minLengthPassword } from '@/config/default/user'
import UserAPI from '@/api/requests/user'
import { toastMapper } from '@/store/modules/toast'
import _ from 'lodash'
const Mapper = Vue.extend({
  methods: {
    ...toastMapper.mapActions(['pushToast'])
  }
})

@Component
export default class ChangePasswordModal extends Mapper {
  private userModel = UserFactory.emptyPassword()
  private isValidForm = false
  private onValidated(isValid: boolean) {
    this.isValidForm = isValid
  }

  private static checkPasswordLength(model: any, field: any) {
    const length = model[field.model]?.length ?? minLengthPassword
    if (length < minLengthPassword) {
      return [`Пароль должен быть не меньше ${minLengthPassword} символов`]
    }
    return []
  }
  private modalSchemaPassword: FormSchema = {
    fields: [
      {
        type: 'bootstrap',
        inputType: 'password',
        model: 'oldPassword',
        label: 'Введите текущий пароль',
        styleClasses: 'w-100',
        required: true,
        validator(value: any, field: any, model: any) {
          return ChangePasswordModal.checkPasswordLength(model, field)
        }
      },
      {
        type: 'bootstrap',
        inputType: 'password',
        model: 'newPassword',
        label: 'Введите новый пароль',
        styleClasses: 'w-100',
        validator(value: any, field: any, model: any) {
          return ChangePasswordModal.checkPasswordLength(model, field)
        }
      },
      {
        type: 'bootstrap',
        inputType: 'password',
        model: 'newPasswordConfirm',
        label: 'Повторите новый пароль',
        styleClasses: 'w-100',
        validator(value: any, field: any, model: any) {
          const passwordLenCheck = ChangePasswordModal.checkPasswordLength(
            model,
            field
          )
          if (!_.isEmpty(passwordLenCheck)) {
            return passwordLenCheck
          }
          if (model.newPasswordConfirm !== model.newPassword) {
            return ['Пароли не совпадают']
          }
          return []
        }
      }
    ]
  }
  private onChangePasswordInput(newVal: any, schema: any) {
    this.$emit('model-updated', newVal, schema)
  }
  private async onChangePasswordConfirm() {
    const changingPassword = {
      password: this.userModel.oldPassword,
      newPassword: this.userModel.newPassword
    }
    const response = await UserAPI.changePassword(changingPassword)
    if (response.status === 200) {
      this.pushToast({
        time: 5,
        title: 'Успешно',
        message: 'Ваш пароль изменен'
      })
    } else {
      this.pushToast({
        error: true,
        time: 5,
        title: 'Ошибка',
        message: 'Не удалось изменить пароль'
      })
    }
  }
  private options: FormOptions = {
    validateAfterLoad: true,
    validateAfterChanged: true,
    validateAsync: true
  }
  private onHidden() {
    this.userModel = UserFactory.emptyPassword()
  }
}
