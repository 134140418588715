










import { Component, Vue, Emit } from 'vue-property-decorator'

@Component
export default class LoadFileButton extends Vue {
  private onFileBtnClick() {
    ;(this.$refs.fileInput as any).click()
  }
  @Emit('input')
  private onInput(event: any) {
    return event
  }
}
