export enum notifications {
  'notifications' = 'notifications', // Уведомления
  'news' = 'news', // Новости
  'events' = 'events' // События
}

export const notificationsTranslation: { [k: string]: string } = {
  [notifications.notifications]: 'Уведомления',
  [notifications.news]: 'Новости',
  [notifications.events]: 'События'
}
